<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="user" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-header size="md" style="margin-bottom: -3px">
            {{ user.name }}
          </getecma-header>
          <getecma-small class="pb--xxl">
            {{ permission }}
          </getecma-small>
          <getecma-user-view-information :user="user" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { getters } from '@/modules/user/user.store';
import { toastError } from '@/services/toastService';
import { getUserById } from '@/modules/user/user.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaUserViewInformation from '@/modules/user/components/UserViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaUserViewPage',
  components: {
    GetecmaUserViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Usuários', path: MY_SPACE_URL.users.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      user: null,
      permission: null,
      currentUser: getters.getUser(),
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    user_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getUserById(this.user_id)
        .then(data => {
          this.user = data;
          this.permission = USERS_ROLES[this.user.permission];
        })
        .catch(() => toastError('Erro ao obter usuário por ID'));
    },
  },
};
</script>
