<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Nome:</b> {{ user.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>E-mail:</b> {{ user.email }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Permissão:</b> {{ USERS_ROLES[user.permission] }}
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { USERS_ROLES } from '@/modules/user/user.constants';

export default {
  name: 'GetecmaUserViewInformation',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      USERS_ROLES,
    };
  },
};
</script>
